import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import TitleShape from "../../Assets/images/platinum/line-black.png";
import alor from "../../Assets/images/al-brand-logos-img/alor.png";
import Michele from "../../Assets/images/al-brand-logos-img/Michele-Watch.png";
import Bellross from "../../Assets/images/al-brand-logos-img/Bell-Ross.png";
import belgium from "../../Assets/images/al-brand-logos-img/bd-logo.png";
import bulova from "../../Assets/images/al-brand-logos-img/Bulova.png";
import longines from "../../Assets/images/al-brand-logos-img/longines.png";
import oris from "../../Assets/images/al-brand-logos-img/Oris.png";
// import overnight from "../../Assets/images/al-brand-logos-img/overnight.png";
import frederique from "../../Assets/images/al-brand-logos-img/Frederique-Constant.png";
// import stuller from "../../Assets/images/al-brand-logos-img/Stuller.png";
import Benchmark from "../../Assets/images/al-brand-logos-img/Benchmark.png";

import Butani from "../../Assets/images/jewelry-brands-logo/butani-logo.svg";
import CGonshor from "../../Assets/images/jewelry-brands-logo/c-gonshor.gif";
import Carerra from "../../Assets/images/jewelry-brands-logo/carerra.png";
import Damosa from "../../Assets/images/jewelry-brands-logo/damosa.png";
import Doves from "../../Assets/images/jewelry-brands-logo/doves-logo.svg";
import LeVian from "../../Assets/images/jewelry-brands-logo/le-vian.png";
import LeoPizzo from "../../Assets/images/jewelry-brands-logo/LeoPizzo-logo.png";
import NDS from "../../Assets/images/jewelry-brands-logo/nds-logo2.png";
import Pandora from "../../Assets/images/jewelry-brands-logo/pandora.png";
import BelgiumWatch from "../../Assets/images/jewelry-brands-logo/Belgium-Watches-logo_6000x6000.png";

import Slider from "react-slick";
import { isMobileOnly, isDesktop } from "react-device-detect";
import Banckmark from "../Pages/Banckmark";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const BrandLogos = () => {
  const brandLogosData = [
    {
      brandName: "Alor",
      brandLogo: alor,
      path: "/jewelry-brand/alor",
    },
    {
      brandName: "Michele",
      brandLogo: Michele,
      path: "/michele-watch",
    },

    {
      brandName: "Bellross",
      brandLogo: Bellross,
      path: "/bell-and-ross",
    },
    // {
    //   brandName: "belgium",
    //   brandLogo: belgium,
    // },
    {
      brandName: "bulova",
      brandLogo: bulova,
      path: "/watches/bulova",
    },

    {
      brandName: "longines",
      brandLogo: longines,
      path: "/longines",
    },
    {
      brandName: "oris",
      brandLogo: oris,
      path: "/ories",
    },
    // {
    //   brandName: "overnight",
    //   brandLogo: overnight,
    // },
    {
      brandName: "frederique_constant",
      brandLogo: frederique,
      path: "/watches/Frederique Constant",
    },
    // {
    //   brandName: "stuller",
    //   brandLogo: stuller,
    // },
    {
      brandName: "Benchmark",
      brandLogo: Benchmark,
      path: "/jewelry-brand/benchmark",
    },

    {
      brandName: "Butani",
      brandLogo: Butani,
      path: "/jewelry-brand/butani",
    },
    {
      brandName: "C.Gonshor",
      brandLogo: CGonshor,
      path: "/jewelry-brand/c-gonshor",
    },
    {
      brandName: "Carrera Y Carrera",
      brandLogo: Carerra,
      path: "/jewelry-brand/carrera-y-carrera/carrera-y-carrera",
    },
    {
      brandName: "Damaso Martinez",
      brandLogo: Damosa,
      path: "/jewelry-brand/damaso-martinez",
    },
    {
      brandName: "Doves By Doran Palomo",
      brandLogo: Doves,
      path: "/jewelry-brand/doves",
    },
    {
      brandName: "Le Vian",
      brandLogo: LeVian,
      path: "/jewelry-brand/le-vian",
    },
    {
      brandName: "Leo Pizzo",
      brandLogo: LeoPizzo,
      path: "/jewelry-brand/leo-pizzo",
    },
    // {
    //   brandName: "Novel Design Studio",
    //   brandLogo: NDS,
    // },
    {
      brandName: "Pandora",
      brandLogo: Pandora,
      path: "/jewelry-brand/pandora",
    },
    // {
    //   brandName: "Belgium Watches",
    //   brandLogo: BelgiumWatch,
    // },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="al_brand_logo_section">
        <Container className="brand_logo_container">
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className="sj_title_main">
                <h2>OUR THOUGHTFULLY CHOSEN ARRAY OF BRANDS</h2>
                <img src={TitleShape} alt="Heading Img" />
              </div>
            </Col>
          </Row>
          <Row>
            {isDesktop ? (
              <Col sm={12} md={12} lg={12}>
                <ul className="al_brand_logo_list">
                  {brandLogosData.map((val) => (
                    <li>
                      <Link to={`${val?.path}`}>
                        <div className="brand_logo_img">
                          <Image src={val.brandLogo} />
                          {/* <h6>{val.brandName}</h6> */}
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </Col>
            ) : (
              <Col sm={12} md={12} lg={12}>
                <div className="al_logo_slider_mobile_view">
                  <Slider {...settings}>
                    {brandLogosData.map((val) => (
                      <Link to={`${val?.path}`}>
                        <div className="brand_logo_img_outer">
                          <div className="brand_logo_img">
                            <Image src={val.brandLogo} />
                            {/* <h6>{val.brandName}</h6> */}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </Slider>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </div>

      
    </>
  );
};

export default BrandLogos;
