import axios from "axios";
import { useEffect } from "react";
import { isDesktop } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { base_url, isLogin, postHeader } from "../Helpers/request";
import { menu, sessionId } from "../Reducer/homeReducer";
import { metaDetails } from "../Reducer/metaReducer";
import { persistor } from "../Store/store";

export default function MainRequest() {
    const session = useSelector(state => state.persistedReducer.home.session_id)
    const routePath = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        GotoTop();
        GoogleAnalytics();
        MetaTag();
    }, [routePath]);

    
    useEffect( ()=>{
     clearlocal();
        SessionId();
        // megamenu();
    }, [])
//--------------------- clear localstorage------------------
    const clearlocal = async () => {
        var localdate = localStorage.getItem('bw-localdate');
        var date = new Date().getDate();
        if (localdate != date) {
          await persistor.flush();
          localStorage.clear();
          sessionStorage.clear();
          localStorage.setItem("bw-localdate", date);
        //   window.location.reload()
        }
      }

    //-------------------- go to top ---------------------
    const GotoTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }


    //------------------- google analytics ----------------------
    const GoogleAnalytics = () => {
        const script = document.createElement('script');
        script.src = "https://www.googletagmanager.com/gtag/js?id=UA-232667779-1";
        script.async = true;
        document.body.appendChild(script);
        window.dataLayer = window.dataLayer || [];
        function gtag() { window.dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'UA-232667779-1', { page_path: window.location.pathname });
    }


    //------------------- Meta tag ---------------------
    const MetaTag = () => {
        axios.get(`${base_url}/common/metatag?url=${window.location.pathname.slice(1)}`).then(res => {
            if (res.data.status == 1) {
                dispatch(metaDetails(res.data.data))
            }
        }).catch(error => console.log(error))
    }

    //------------------- Session Id -----------------
    const SessionId = () => {

        if (session?.length || isLogin) {
            console.log("session exist!")
        } else {
            axios.get(base_url + '/home/getsessionid')
                .then(res => {
                    if (res.data.status == 1) {
                        dispatch(sessionId(res.data.data?.session_id))
                    } else {
                        // toast.error(res.message,{autoClose: 3000});
                        console.log("sessionid not generated")
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }



    return null;

}

