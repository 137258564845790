import { RiFacebookBoxFill , RiTwitterFill, RiInstagramLine, RiLinkedinBoxFill} from "react-icons/ri";
import { Navigate, Outlet } from "react-router";
import Social1 from '../Assets/images/static/Social/1.png';
import Social2 from '../Assets/images/static/Social/2.png';
import Social3 from '../Assets/images/static/Social/3.png';
import Social4 from '../Assets/images/static/Social/4.png';
import Social5 from '../Assets/images/static/Social/5.png';
import Social6 from '../Assets/images/static/Social/6.png';
import Social7 from '../Assets/images/static/Social/7.png';
//-------------------------------------------------url

export const base_url = "https://aliciasjewelers.com/webapi/api";
export const img_url = "https://aliciasjewelers.com/";
export const helmet_url = "https://aliciasjewelers.com/";

// ------Contact Details Start ------
export const contact_number = {                // <===---contact Number //
  text:'+1 (877) 254-2427',   
  link:`tel:+18772542427`
};

export const Email_details = {                      // <===---Email_Details  //
  text:'service@aliciasjewelers.com',
  link:`mailto:service@aliciasjewelers.com`
};

export const Email_detailsSecond = {                // <===---Second_Email_details  // 
  text:'',
  link:``
};
export const Address_details = {                     // <===---Address_details  // 
  textLine1:'211-19 26th Ave',
  textLine2:'Bayside NY 11360',
  textLine3:' 1-877-ALICIAS',
  link:"https://www.google.com/maps/place/Alicia's+Jewelers/@40.7792982,-73.778894,15z/data=!4m15!1m8!3m7!1s0x89c28a7c53b7c883:0xc83e428ecbf8e95f!2sAlicia's+Jewelers!8m2!3d40.7792982!4d-73.778894!10e1!16s%2Fg%2F12613g618!3m5!1s0x89c28a7c53b7c883:0xc83e428ecbf8e95f!8m2!3d40.7792982!4d-73.778894!16s%2Fg%2F12613g618?entry=ttu"
};

export const client_name = {                          // <===---client_name  // 
  c_name:'Alicias jewelers',
};

export const media_details = [                           // <===---media_details  // 
  {                                        
  account_alt:'Facbook',
  account_icon:<RiFacebookBoxFill />,
  account_img : Social1,
  account_link:"https://www.facebook.com/AliciasJewelers"
},
// {                                        
//   account_alt:'Twitter',
//   account_icon:<RiTwitterFill />,
//   account_img : Social7,
//   account_link:"https://twitter.com/aliciasjewelers"
// },
{                                        
  account_alt:'Instgram',
  account_icon:<RiInstagramLine />,
  account_img : Social2,
  account_link:"https://www.instagram.com/alicias_jewelers/"
},
// {                                        
//   account_alt:'pinterest',
//   account_icon: <RiLinkedinBoxFill />,
//   account_img : Social4,
//   account_link:"https://in.pinterest.com/AliciasJewelers/"
// },
];


export const InvoiceAddress =()=> {              // <===---Invoice_Address  //
  return(
    <>
    <h4 className="mb-2">26th Ave, Bayside NY 11360</h4>
    <h4 className="mb-0"> 1-877-ALICIAS</h4>
    </>
   )
  };
  // ------Contact Details End ------

export const user = JSON.parse(localStorage.getItem("bw-user")) ? JSON.parse(localStorage.getItem("bw-user")) : [];

export const isLogin = JSON.parse(localStorage.getItem("bw-user")) ? JSON.parse(localStorage.getItem("bw-user")).isLogin : false;
export const currencycode = localStorage.getItem("bw-currency") ? localStorage.getItem("bw-currency") : 'USD';
export const currency = localStorage.getItem("bw-currencyicon") ? localStorage.getItem("bw-currencyicon") : '$';
//--------------------------------------------------Api header data
export const postHeader = {
  // 'authorization':`Token e222085edc871220cae528152b73c1317ba2addf`,
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

export const onTop = () => {
  window.scrollTo(0, 0);
}

export const ProtectedRoutes = () => {
  return isLogin ? <Outlet /> : <Navigate to="/" />
}
//---------------------------------------------------Ring builder
export const setSteps = (check) => {
  const diamonddata = JSON.parse(localStorage.getItem('bw-diamonddata')) ? JSON.parse(localStorage.getItem('bw-diamonddata')) : null;
  const settingdata = JSON.parse(localStorage.getItem('bw-settingdata')) ? JSON.parse(localStorage.getItem('bw-settingdata')) : null;
  const gemstonedata = JSON.parse(localStorage.getItem('bw-gemstonedata')) ? JSON.parse(localStorage.getItem('bw-gemstonedata')) : null;
  const fancycolordata = JSON.parse(localStorage.getItem('bw-fancycolordata')) ? JSON.parse(localStorage.getItem('bw-fancycolordata')) : null;
  var arr;
   if (check == 0) {
    if (settingdata == null) {
       arr = [0, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (settingdata != null) {
       arr = [3, 0];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  }else if (check == 1) {
    if (settingdata == null) {
       arr = [1, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (settingdata != null) {
       arr = [3, 1];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 2) {
    if (settingdata == null) {
       arr = [2, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (settingdata != null) {
       arr = [3, 2];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 3) {
    if (diamonddata == null) {
       arr = [3, 1];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (diamonddata != null) {
       arr = [1, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 4) {
    if (gemstonedata == null) {
       arr = [3, 2];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (gemstonedata != null) {
       arr = [2, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } else if (check == 5) {
    if (fancycolordata == null) {
       arr = [3, 0];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    } else if (fancycolordata != null) {
       arr = [0, 3];
      sessionStorage.setItem("bw-step", JSON.stringify(arr))
    }
  } 
}
