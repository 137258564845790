import React, { useEffect, useState } from 'react';
import '../../Assets/css/home.css';
import { Col, Row, Container, Carousel } from 'react-bootstrap';

import { NavLink, useHistory } from 'react-router-dom';
import Slider from 'react-slick';

import Banner1 from '../../Assets/images/alicias-img/engagment-banen-desktop.webp';
import Banner2 from '../../Assets/images/alicias-img/fine-banner-desktop.webp';
import LazyLoad from 'react-lazy-load';
import axios from 'axios';
import { base_url, postHeader } from '../../Helpers/request';
import { toast } from 'react-toastify';
import { Skeleton } from '@mui/material';
import { isMobile } from 'react-device-detect';

const BannerSlider = () => {
  const history = useHistory();
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //-----------------------------------Banner
    setLoading(true)
    axios
      .get(base_url + `/home/slider`, {
        headers: postHeader,
      })
      .then((response) => {
        if (response.data.status == 1) {
          setLoading(false)
          setBanner(response.data.data);
          localStorage.setItem('bw-bannerdata', JSON.stringify(response.data.data));
        } else {
          toast.error(response.message, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    centerPadding: '20px',
    accessibility: true,
    arrows: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <LazyLoad className='lazyLoad-heroBanner' width="100%" threshold={0.60}>
        <div className="bannerup">
          <div className="banner-slider hide-mob">
            <Container fluid className="rcs_custom_banner_container p-0">
              <Row className="w-100 m-0">
                <Col lg={12} className='p-0'>
                  <div className='bannner_banenr_slider'>
                    <Carousel fade indicators={false}>
                      {loading ? (
                        <Skeleton height={!isMobile?'680px':"225px"} style={{ transform: 'scale(1)', background: "#f7f7f7" }} animation="wave" />
                      ) : (
                      banner?.map((itme, index) =>
                        <Carousel.Item>
                          <div className="pd_banner_slider_main">
                            <NavLink to="/ringsettings">
                              {' '}
                              <img src={itme.image} />{' '}
                            </NavLink>
                          </div>
                        </Carousel.Item>)
                      )}
                    </Carousel>
                  </div>

                  {/* <Slider {...settings} className="rcs_slider_img">
                  <div className="pd_banner_slider_main">
                    <NavLink to="/ringsettings">
                      {' '}
                      <img src={Banner1} />{' '}
                    </NavLink>
                  </div>
                  <div className="pd_banner_slider_main">
                    <NavLink to="/jewelry/fine-jewelry">
                      {' '}
                      <img src={Banner2} />{' '}
                    </NavLink>
                  </div>
                </Slider> */}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </LazyLoad>
      {/* <div className='rcs_home_video_main'>
                  <ReactPlayer url="https://dl2vs6wk2ewna.cloudfront.net/media/adele.mp4" width='100%'
                    height='100%'
                    playing
                    loop
                    muted
                    playsinline
                  />
                </div> */}
      {/* 
      <div className="rcs_hero_img_d">
        <Row className='m-auto w-100'>
          <Col md={12} className='m-auto p-0'>
          


            <div className="rcs_hero_slider str_jeweler_banner">
              <div className="rcs_hero_img">
                <Container className='rcs_custom_home_container'>
                  <Row className='m-auto w-100'>
                    <Col md={12} className='m-auto'>
                      <div className='text-left  ml-1'>
                        <h4 > Love & Engagement </h4>
                        <h1>Diamond Rings</h1>
                        <p >Shop Our Latest Collection of Diamond Engagement Rings</p>
                        <Button variant="outline-dark" className='sj_border_btn' onClick={() => history.push('/collections/engagement-rings')}>Shop Now <BsArrowRightShort /> </Button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>


          </Col>
        </Row>


      </div> */}

    </>
  );
};

export default BannerSlider;
