import React from 'react';
import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const Banckmark = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.src =
      'https://www.benchmarkrings.com/iframe/load/PcEcdfMqilOnSvlKIQToHg';
    script.async = true;

    // Append the script to the document body
    document.body.appendChild(script);

    // Clean up: remove the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <div className="al_banckmark_section">
        <Container fluid>
          <Row>
            <Col sm={12} md={12} lg={12} className="p-0">
              <div id="benchmark-rings-embed">
                {/* Content loaded by the external script will be rendered here */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Banckmark;
